import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import Nav from '../components/Nav';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const Round = styled.div`
  border-radius: 50%;
  width: 168px;
  height: 168px;

  background-color: black;
  display: inline-block;
  background-image: url('jmb-round.jpg');
  background-size: 168px;
  border: 1px solid grey;
  margin-bottom: -0.8rem;
  @media (max-width: 768px) {
    margin-left: 0.25rem;
    margin-bottom: -0.3rem;
  }
  @media (max-width: 1680px) {
    width: 10vw;
    height: 10vw;
    background-size: 10vw;
  }
`;

export default function HomePage() {
  return (
    <>
      <SEO title="Home" />
      <h1>
        <span className="sans big heavy">
          Hi! I&#8217;m James. <Round /> <br />
          I&#8217;m an NYC-based designer and leader.
        </span>
      </h1>
      <p>
        <span className="sans big light">
          {' '}
          I help teams make compelling digital experiences .{' '}
        </span>
      </p>
      {/* <p style={{ marginTop: '1.5em' }}> */}
      {/* <span className="sans medium  light"> */}
      {/* {' '}
          I&#8217;m currently a{' '}
          <a
            className="biglink"
            href="https://www.siegelgale.com/people/james-barnes/"
            target="blank"
          >
            Director at Siegel+Gale
          </a>
          , a global brand experience agency based in New York.
        </span>
      </p>
      <p style={{ marginTop: '1.5em' }}>
        <span className="sans medium light">
          {' '}
          In the off hours, I&#8217;m building{' '}
          <a
            className="biglink"
            href="https://www.deadsound.app/about/"
            target="blank"
          >
            Deadsound
          </a>
          , a mobile app for finding and streaming Grateful Dead tapes.
        </span> */}
      {/* </p> */}
      {/* <p className="sans big"></p> */}
    </>
  );
}

// export const pageQuery = graphql`
//   query {
//     largeImage: file(relativePath: { eq: "jmb-round.jpg" }) {
//       childImageSharp {
//         fixed(width: 125) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//     medImage: file(relativePath: { eq: "jmb-round.jpg" }) {
//       childImageSharp {
//         fixed(width: 90) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//     smallImage: file(relativePath: { eq: "jmb-round.jpg" }) {
//       childImageSharp {
//         fixed(width: 60) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//   }
// `;
